<template>
    <nav class="nav game-selector pb-1">
        <div class="d-flex flex-sm-column flex-md-row flex-wrap my-auto">
            <div class="d-sm-inline-flex d-md-flex mx-2 my-auto">
                <filter-box
                    class="mt-1"
                    :options="collection.options"
                    v-model="collection.selected"
                    labelSearchPlaceholder="Collection"
                    searchable adv 
                    @input="filterChanged('collection', $event)"
                />
            </div>

            <div class="divider my-auto mx-2">&nbsp;</div>

            <div class="d-sm-inline-flex d-md-flex mx-2 my-auto">
                <filter-box
                    class="mt-1"
                    :options="playerclass.options"
                    v-model="playerclass.selected"
                    labelSearchPlaceholder="Class"
                    searchable adv
                    @input="filterChanged('class', $event)"
                />
            </div>

            <div class="divider my-auto mx-2">&nbsp;</div>

            <div class="d-sm-inline-flex d-md-flex mx-2 my-auto">
                <filter-box
                    class="mt-1"
                    :options="grade.options"
                    v-model="grade.selected"
                    labelSearchPlaceholder="Grade"
                    searchable adv 
                    @input="filterChanged('grade', $event)"
                />
            </div>

            <div class="divider my-auto mx-2">&nbsp;</div>

            <div class="d-sm-inline-flex d-md-flex mx-2 my-auto">
                <filter-box
                    class="mt-1"
                    :options="exterior.options"
                    v-model="exterior.selected"
                    labelSearchPlaceholder="Exterior"
                    searchable adv
                    @input="filterChanged('wear', $event)"
                />
            </div>

            <div class="divider my-auto mx-2">&nbsp;</div>

            <div class="d-sm-inline-flex d-md-flex mx-2 my-auto">
                <filter-box
                    class="mt-1"
                    :options="unusual.options"
                    v-model="unusual.selected"
                    labelSearchPlaceholder="Unusual Effect"
                    searchable adv
                    @input="filterChanged('unusual', $event)"
                />
            </div>

            <div class="divider my-auto mx-2">&nbsp;</div>

            <div class="d-sm-inline-flex d-md-flex mx-2 my-auto">
                <sticker-filter-box
                    class="mt-1"
                    :options="parts.options"
                    v-model="parts.selected"
                    searchable adv
                    prefix="Attachment"
                    @input="filterChanged('strangeparts', $event)"
                />
            </div>
        </div>
    </nav>
</template>

<script>
import FilterBox from "../../../components/FilterBox";
import StickerFilterBox from "../../../components/StickerFilterBox";
import { EventBus } from "../../../utils/eventBus.js";

export default {
    data() {
        return {
            collection: {
                selected: { value: null, text: null },
                options: [
                    { value: "Any", text: "Any" },
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/community_cosmetic_case_summer2021.c7f1128ce51379dfeb37d130dfd3375ca333f937.png", text: "Summer 2021 Cosmetics", value: "Summer 2021 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_workshop_paintkit_smissmas2020.805910f6351d2e719eafb4c3d5a770b43f95c818.png", text: "Winter 2020 War Paints", value: "Winter 2020 War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/smissmas_2020_case.05605b8feb77b8cbbe53b964a30dfa5baaa579c2.png", text: "Winter 2020 Cosmetics", value: "Winter 2020 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_workshop_paintkit_halloween2020.8cb4ab1a657e2cc909748f98648deab85211c9f1.png", text: "Halloween 2020 War Paints", value: "Halloween 2020 War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/halloween2020_case.4e5b01e409c30689770c1b1a098d27be0c40d9f8.png", text: "Halloween 2020 Cosmetics", value: "Halloween 2020 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/community_cosmetic_case_summer2020.b7aa82c4a580b5011db3c9b16cfd23cd2c26b0eb.png", text: "Summer 2020 Cosmetics", value: "Summer 2020 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/smissmas_2019_case.a6ea45ad2b78077a676b63a8869a156484a74508.png", text: "Winter 2019 Cosmetics", value: "Winter 2019 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_workshop_paintkit_smissmas2019.6206634ada5fc0222e107e941ed2df98e8698cf5.png", text: "Winter 2019 War Paints", value: "Winter 2019 War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/halloween2019_case.dcc61405d34fe30b5f205d8f6fdb30479256892f.png", text: "Halloween 2019 Cosmetics", value: "Halloween 2019 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/smissmas_2018_case.793107c12a4e6330ab2e34e99c761d32753407e5.png", text: "Winter 2018 Cosmetics", value: "Winter 2018 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/halloween2018_case.1f12abdd8409cbf24ddf838278dc4436918529a7.png", text: "Halloween 2018 Cosmetics", value: "Halloween 2018 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_workshop_paintkit_halloween2018.d8726f6d0535744620118bc86054d63240a6fa15.png", text: "Halloween 2018 War Paints", value: "Halloween 2018 War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/community_cosmetic_case_bluemoon.f7c8a83cc854d7d8c4377aecc024e6b11513fad2.png", text: "Blue Moon Cosmetics", value: "Blue Moon Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/smissmas_2017_case.a5e8c70c8342d4581f8ffac9c0faa056dbf8d5dc.png", text: "Winter 2017 Cosmetics", value: "Winter 2017 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_workshop_paintkit_smissmas2017.26a8de490732ede57907f896ad891c9ef6efcdfb.png", text: "Winter 2017 War Paints", value: "Winter 2017 War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/paintkit_bundle_02.28b101f06aa9c35ec864e534f2bb66cb521de842.png", text: "Decorated War Hero", value: "Decorated War Hero Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/paintkit_bundle_01.8e42675eb95aab91ade23dd19f1449aa20bf1a2b.png", text: "Contract Campaigner", value: "Contract Campaigner Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/cosmetic_case_jungle_croc.40fb6d57a78b43a83e5e988428e47f65cc25d41a.png", text: "Unleash the Beast Cosmetics", value: "Unleash the Beast Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/cosmetic_case_jungle_yeti.48026ef937e5ac338dd8d874c055314830c01905.png", text: "Abominable Cosmetics", value: "Abominable Cosmetic Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_workshop_paintkit.1f216fe5d925ed75fbac02f114df79cf9c04d61e.png", text: "Infernal Reward War Paints", value: "Infernal Reward War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_paintkit.2e3fd94bd3b3d1dbba6ce1a1e1448133d3fb0f8d.png", text: "Jungle Jackpot War Paints", value: "Jungle Jackpot War Paints"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/community_cosmetic_case_rainyday.8351f16163c303e5c2d338f11628f26ba40798b9.png", text: "Rainy Day Cosmetics", value: "Rainy Day Cosmetics"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/winter_2016_case.95104ee80201880380a024291560f7544efe75e5.png", text: "Winter 2016 Cosmetics", value: "Winter 2016 Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/halloween2016_case.b30daf9f4555403e76a35d5f407ebaa21ed9a8fe.png", text: "Creepy Crawly", value: "Creepy Crawly Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/cosmetic_case_spring.d6e5aef871c59a4ab499f117c59e317de40a6d9c.png", text: "Mayflower Cosmetics", value: "Mayflower Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/cosmetic_case_tough_break.608e4adb141fff93e272359f2d25f2dcef4fd036.png", text: "Tough Break Cosmetics", value: "Tough Break Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_04_warbirds.6ada841cdcfaa70c3edbb520f7e3e08943e738a8.png", text: "Warbird Skins", value: "Warbird Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_03_pyroland.d4118f044b367236c7583bec8840155e00c004d8.png", text: "Pyroland Skins", value: "Pyroland Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/ticket_tough_break.488ae3674628e596fc16b97a19b67bd41816fb21.png", text: "Gentlemanne's Collection Skins", value: "Gentlemanne's Collection Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/ticket_tough_break.488ae3674628e596fc16b97a19b67bd41816fb21.png", text: "Harvest Skins", value: "Harvest Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/halloween2015_case.5555e3d820f641746bcf3dfeb428adc62de19c02.png", text: "Gargoyle", value: "Gargoyle Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/invasion_case.ed53f2eb2a055b97a22f62434517e5797c42fe84.png", text: "Quarantined", value: "Quarantined Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/invasion_case_rare.9fb395e91e493c9e2a8b27d159a059fb850c7ea3.png", text: "Confidential", value: "Confidential Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/cosmetic_case.d95ea4ab454776b932899b9e08cf0a8195969ecf.png", text: "Gun Mettle Cosmetics", value: "Gun Mettle Cosmetics Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_02_powerhouse.5eb0ee5d0b8e64d0b08914f640d29279f270555d.png", text: "Powerhouse Skins", value: "Powerhouse Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/weapons_case_01_concealed.e542efa80c20108cc35db0b703346e6aa5cbdb2e.png", text: "Concealed Killer Skins", value: "Concealed Killer Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/ticket_summer2015.b646a886dbb24f45bee68fbe1e2bf4c8034b6e1b.png", text: "Craftsmann Skins", value: "Craftsmann Skins Collection"},
                    {image:"https://steamcdn-a.akamaihd.net/apps/440/icons/ticket_summer2015.b646a886dbb24f45bee68fbe1e2bf4c8034b6e1b.png", text: "Teufort Skins", value: "Teufort Skins Collection"}
                ]
            },
            playerclass: {
                selected: { value: null, text: null },
                options: [
                    { value: "Any", text: "Any" },
                    { value: "All Class", text: "All Class" },
                    { value: "Demoman", text: "Demoman" },
                    { value: "Engineer", text: "Engineer" },
                    { value: "Heavy", text: "Heavy" },
                    { value: "Medic", text: "Medic" },            
                    { value: "Pyro", text: "Pyro" },
                    { value: "Scout", text: "Scout" },
                    { value: "Sniper", text: "Sniper" },
                    { value: "Soldier", text: "Soldier" },
                    { value: "Spy", text: "Spy" }
                ]
            },
            grade: {
                selected: { value: null, text: null },
                options: [
                    { value: "Any", text: "Any" },
                    { value: "Mercenary", text: "Mercenary", color: "rgb(75, 105, 255)" },
                    { value: "Commando", text: "Commando", color: "rgb(136, 71, 255)" },
                    { value: "Civilian", text: "Civilian", color: "rgb(176, 195, 217)" },
                    { value: "Freelance", text: "Freelance", color: "rgb(94, 152, 217)" },
                    { value: "Assassin", text: "Assassin", color: "rgb(211, 44, 230)" },
                    { value: "Elite", text: "Elite", color: "rgb(235, 75, 75)" }
                ]
            },
            exterior: {
                selected: { value: null, text: null },
                options: [
                    { value: "Any", text: "Any" },
                    { value: "Factory New", text: "Factory New" },
                    { value: "Minimal Wear", text: "Minimal Wear" },
                    { value: "Field-Tested", text: "Field-Tested" },
                    { value: "Well-Worn", text: "Well-Worn" },
                    { value: "Battle Scarred", text: "Battle Scarred" }
                ]
            },
            unusual: {
                selected: { value: null, text: null },
                options: [
                    { value: "Burning Flames", text: "Burning Flames" },
                    { value: "Circling Heart", text: "Circling Heart" },
                    { value: "Circling Peace Sign", text: "Circling Peace Sign" },
                    { value: "Circling TF Logo", text: "Circling TF Logo" },
                    { value: "Green Confetti", text: "Green Confetti" },
                    { value: "Green Energy", text: "Green Energy" },
                    { value: "Haunted Ghosts", text: "Haunted Ghosts" },
                    { value: "Massed Flies", text: "Massed Flies" },
                    { value: "Purple Confetti", text: "Purple Confetti" },
                    { value: "Purple Energy", text: "Purple Energy" },
                    { value: "Scorching Flames", text: "Scorching Flames" },
                    { value: "Searing Plasma", text: "Searing Plasma" },
                    { value: "Sunbeams", text: "Sunbeams" },
                    { value: "Vivid Plasma", text: "Vivid Plasma" },
                    { value: "Blizzardy Storm", text: "Blizzardy Storm" },
                    { value: "Bubbling", text: "Bubbling" },
                    { value: "Nuts n' Bolts", text: "Nuts n' Bolts" },
                    { value: "Orbiting Fire", text: "Orbiting Fire" },
                    { value: "Orbiting Planets", text: "Orbiting Planets" },
                    { value: "Smoking", text: "Smoking" },
                    { value: "Steaming", text: "Steaming" },
                    { value: "Stormy Storm", text: "Stormy Storm" },
                    { value: "Aces High", text: "Aces High" },
                    { value: "Cloud 9", text: "Cloud 9" },
                    { value: "Dead Presidents", text: "Dead Presidents" },
                    { value: "Disco Beat Down", text: "Disco Beat Down" },
                    { value: "Kill-a-Watt", text: "Kill-a-Watt" },
                    { value: "Miami Nights", text: "Miami Nights" },
                    { value: "Terror-Watt", text: "Terror-Watt" },
                    { value: "Community Sparkle", text: "Community Sparkle" },
                    { value: "Aromatica", text: "Aromatica" },
                    { value: "Bee Swarm", text: "Bee Swarm" },
                    { value: "Chromatica", text: "Chromatica" },
                    { value: "Frisky Fireflies", text: "Frisky Fireflies" },
                    { value: "Kaleidoscope", text: "Kaleidoscope" },
                    { value: "Prismatica", text: "Prismatica" },
                    { value: "Smoldering Spirits", text: "Smoldering Spirits" },
                    { value: "Verdatica", text: "Verdatica" },
                    { value: "Wandering Wisps", text: "Wandering Wisps" },
                    { value: "Cauldron Bubbles", text: "Cauldron Bubbles" },
                    { value: "Cloudy Moon", text: "Cloudy Moon" },
                    { value: "Eerie Orbiting Fire", text: "Eerie Orbiting Fire" },
                    { value: "Flaming Lantern", text: "Flaming Lantern" },
                    { value: "Harvest Moon", text: "Harvest Moon" },
                    { value: "It's A Secret To Everybody", text: "It's A Secret To Everybody" },
                    { value: "Knifestorm", text: "Knifestorm" },
                    { value: "Misty Skull", text: "Misty Skull" },
                    { value: "Stormy 13th Hour", text: "Stormy 13th Hour" },
                    { value: "Arcana", text: "Arcana" },
                    { value: "Chiroptera Venenata", text: "Chiroptera Venenata" },
                    { value: "Darkblaze", text: "Darkblaze" },
                    { value: "Demonflame", text: "Demonflame" },
                    { value: "Hellfire", text: "Hellfire" },
                    { value: "Poisoned Shadows", text: "Poisoned Shadows" },
                    { value: "Something Burning This Way Comes", text: "Something Burning This Way Comes" },
                    { value: "Spellbound", text: "Spellbound" },
                    { value: "Amaranthine", text: "Amaranthine" },
                    { value: "Bonzo The All-Gnawing", text: "Bonzo The All-Gnawing" },
                    { value: "Ghastly Ghosts Jr", text: "Ghastly Ghosts Jr" },
                    { value: "Haunted Phantasm Jr", text: "Haunted Phantasm Jr" },
                    { value: "Stare From Beyond", text: "Stare From Beyond" },
                    { value: "The Ooze", text: "The Ooze" },
                    { value: "Ghastly Ghosts", text: "Ghastly Ghosts" },
                    { value: "Haunted Phantasm", text: "Haunted Phantasm" },
                    { value: "Ancient Eldritch", text: "Ancient Eldritch" },
                    { value: "Death by Disco", text: "Death by Disco" },
                    { value: "Eldritch Flame", text: "Eldritch Flame" },
                    { value: "Ether Trail", text: "Ether Trail" },
                    { value: "It's a mystery to everyone", text: "It's a mystery to everyone" },
                    { value: "It's a puzzle to me", text: "It's a puzzle to me" },
                    { value: "Nether Trail", text: "Nether Trail" },
                    { value: "Neutron Star", text: "Neutron Star" },
                    { value: "Starstorm Insomnia", text: "Starstorm Insomnia" },
                    { value: "Starstorm Slumber", text: "Starstorm Slumber" },
                    { value: "Tesla Coil", text: "Tesla Coil" },
                    { value: "Hellish Inferno", text: "Hellish Inferno" },
                    { value: "Infernal Flames", text: "Infernal Flames" },
                    { value: "Infernal Smoke", text: "Infernal Smoke" },
                    { value: "Spectral Swirl", text: "Spectral Swirl" },
                    { value: "Brain Drain", text: "Brain Drain" },
                    { value: "Clairvoyance", text: "Clairvoyance" },
                    { value: "Galactic Gateway", text: "Galactic Gateway" },
                    { value: "Head of Steam", text: "Head of Steam" },
                    { value: "Omniscient Orb", text: "Omniscient Orb" },
                    { value: "Open Mind", text: "Open Mind" },
                    { value: "Ring of Fire", text: "Ring of Fire" },
                    { value: "The Dark Doorway", text: "The Dark Doorway" },
                    { value: "The Eldritch Opening", text: "The Eldritch Opening" },
                    { value: "Vicious Circle", text: "Vicious Circle" },
                    { value: "White Lightning", text: "White Lightning" },
                    { value: "Acidic Bubbles of Envy", text: "Acidic Bubbles of Envy" },
                    { value: "Flammable Bubbles of Attraction", text: "Flammable Bubbles of Attraction" },
                    { value: "Ominous Night", text: "Ominous Night" },
                    { value: "Poisonous Bubbles of Regret", text: "Poisonous Bubbles of Regret" },
                    { value: "Roaring Rockets", text: "Roaring Rockets" },
                    { value: "Spooky Night", text: "Spooky Night" },
                    { value: "Abyssal Aura", text: "Abyssal Aura" },
                    { value: "Ethereal Essence", text: "Ethereal Essence" },
                    { value: "Fifth Dimension", text: "Fifth Dimension" },
                    { value: "Ghastly Grove", text: "Ghastly Grove" },
                    { value: "Menacing Miasma", text: "Menacing Miasma" },
                    { value: "Mystical Medley", text: "Mystical Medley" },
                    { value: "Twisted Radiance", text: "Twisted Radiance" },
                    { value: "Valiant Vortex", text: "Valiant Vortex" },
                    { value: "Verdant Vortex", text: "Verdant Vortex" },
                    { value: "Vicious Vortex", text: "Vicious Vortex" },
                    { value: "Violet Vortex", text: "Violet Vortex" },
                    { value: "Wicked Wood", text: "Wicked Wood" },
                    { value: "Accursed", text: "Accursed" },
                    { value: "Bewitched", text: "Bewitched" },
                    { value: "Eerie Lightning", text: "Eerie Lightning" },
                    { value: "Enchanted", text: "Enchanted" },
                    { value: "Jarate Shock", text: "Jarate Shock" },
                    { value: "Nether Void", text: "Nether Void" },
                    { value: "Static Mist", text: "Static Mist" },
                    { value: "Terrifying Thunder", text: "Terrifying Thunder" },
                    { value: "Gourdian Angel", text: "Gourdian Angel" },
                    { value: "Gravelly Ghoul", text: "Gravelly Ghoul" },
                    { value: "Green Giggler", text: "Green Giggler" },
                    { value: "Laugh-O-Lantern", text: "Laugh-O-Lantern" },
                    { value: "Plum Prankster", text: "Plum Prankster" },
                    { value: "Pumpkin Party", text: "Pumpkin Party" },
                    { value: "Pyroland Nightmare", text: "Pyroland Nightmare" },
                    { value: "Vexed Volcanics", text: "Vexed Volcanics" },
                    { value: "Arachnid Assault", text: "Arachnid Assault" },
                    { value: "Arcane Assistance (RED)", text: "Arcane Assistance (RED)" },
                    { value: "Arcane Assistance (BLU)", text: "Arcane Assistance (BLU)" },
                    { value: "Astral Presence", text: "Astral Presence" },
                    { value: "Creepy Crawlies", text: "Creepy Crawlies" },
                    { value: "Emerald Allurement", text: "Emerald Allurement" },
                    { value: "Pyrophoric Personality", text: "Pyrophoric Personality" },
                    { value: "Spectral Escort", text: "Spectral Escort" },
                    { value: "Spellbound Aspect", text: "Spellbound Aspect" },
                    { value: "Static Shock", text: "Static Shock" },
                    { value: "Toxic Terrors", text: "Toxic Terrors" },
                    { value: "Veno Shock", text: "Veno Shock" },
                    { value: "Defragmenting Reality (RED)", text: "Defragmenting Reality (RED)" },
                    { value: "Defragmenting Reality (BLU)", text: "Defragmenting Reality (BLU)" },
                    { value: "Fragmented Gluons", text: "Fragmented Gluons" },
                    { value: "Fragmented Photons", text: "Fragmented Photons" },
                    { value: "Fragmented Quarks", text: "Fragmented Quarks" },
                    { value: "Fragmenting Reality", text: "Fragmenting Reality" },
                    { value: "Frozen Icefall", text: "Frozen Icefall" },
                    { value: "Pyroland Daydream (RED)", text: "Pyroland Daydream (RED)" },
                    { value: "Pyroland Daydream (BLU)", text: "Pyroland Daydream (BLU)" },
                    { value: "Refragmenting Reality", text: "Refragmenting Reality" },
                    { value: "Snowblinded", text: "Snowblinded" },
                    { value: "Snowfallen", text: "Snowfallen" },
                    { value: "Sparkling Lights", text: "Sparkling Lights" },
                    { value: "Arctic Aurora", text: "Arctic Aurora" },
                    { value: "Festive Spirit", text: "Festive Spirit" },
                    { value: "Good-Hearted Goodies", text: "Good-Hearted Goodies" },
                    { value: "Magical Spirit", text: "Magical Spirit" },
                    { value: "Winter Spirit", text: "Winter Spirit" },
                    { value: "Wintery Wisp", text: "Wintery Wisp" },
                    { value: "Anti-Freeze", text: "Anti-Freeze" },
                    { value: "Electrostatic", text: "Electrostatic" },
                    { value: "Green Black Hole", text: "Green Black Hole" },
                    { value: "Memory Leak", text: "Memory Leak" },
                    { value: "Overclocked", text: "Overclocked" },
                    { value: "Phosphorous", text: "Phosphorous" },
                    { value: "Power Surge", text: "Power Surge" },
                    { value: "Roboactive", text: "Roboactive" },
                    { value: "Sulphurous", text: "Sulphurous" },
                    { value: "Time Warp", text: "Time Warp" },
                    { value: "'72", text: "'72" },
                    { value: "Fountain of Delight", text: "Fountain of Delight" },
                    { value: "Holy Grail", text: "Holy Grail" },
                    { value: "Mega Strike", text: "Mega Strike" },
                    { value: "Midnight Whirlwind", text: "Midnight Whirlwind" },
                    { value: "Screaming Tiger", text: "Screaming Tiger" },
                    { value: "Showstopper (RED)", text: "Showstopper (RED)" },
                    { value: "Showstopper (BLU)", text: "Showstopper (BLU)" },
                    { value: "Silver Cyclone", text: "Silver Cyclone" },
                    { value: "Skill Gotten Gains", text: "Skill Gotten Gains" },
                    { value: "Death at Dusk", text: "Death at Dusk" },
                    { value: "Frostbite", text: "Frostbite" },
                    { value: "Molten Mallard", text: "Molten Mallard" },
                    { value: "Morning Glory", text: "Morning Glory" },
                    { value: "Cool", text: "Cool" },
                    { value: "Energy Orb", text: "Energy Orb" },
                    { value: "Hot", text: "Hot" },
                    { value: "Isotope", text: "Isotope" },
                    { value: "Abduction", text: "Abduction" },
                    { value: "Ancient Codex", text: "Ancient Codex" },
                    { value: "Atomic", text: "Atomic" },
                    { value: "Electric Hat Protector", text: "Electric Hat Protector" },
                    { value: "Galactic Codex", text: "Galactic Codex" },
                    { value: "Magnetic Hat Protector", text: "Magnetic Hat Protector" },
                    { value: "Nebula", text: "Nebula" },
                    { value: "Subatomic", text: "Subatomic" },
                    { value: "Voltaic Hat Protector", text: "Voltaic Hat Protector" }
                ]
            },
            parts: {
                // let results=[]; $('.item-pricegrid').each(function(){ var li = $(this); results.push({ value: li.find('.name').text(), name: li.find('.name').text(), image: li.css('background-image') }) }); JSON.stringify(results)
                options: [
                    {
                        text: "Effect: Voices From Below",
                        value: "Halloween: Voices From Below",
                        image: require("@/assets/tf2/halloween/effect_voices_from_below.png")
                    },
                    {
                        text: "Footprints: Team Spirit",
                        value: "Halloween: Team Spirit Footprints",
                        image: require("@/assets/tf2/halloween/footprint_team_spirit.png")
                    },
                    {
                        text: "Footprints: Gangreen",
                        value: "Halloween: Gangreen Footprints",
                        image: require("@/assets/tf2/halloween/footprint_gangreen.png")
                    },
                    {
                        text: "Footprints: Corpse Gray",
                        value: "Halloween: Corpse Gray Footprints",
                        image: require("@/assets/tf2/halloween/footprint_corpse_gray.png")
                    },
                    {
                        text: "Footprints: Violent Violet",
                        value: "Halloween: Violent Violet Footprints",
                        image: require("@/assets/tf2/halloween/footprint_violent_violet.png")
                    },
                    {
                        text: "Footprints: Rotten Orange",
                        value: "Halloween: Rotten Orange Footprints",
                        image: require("@/assets/tf2/halloween/footprint_rotten_orange.png")
                    },
                    {
                        text: "Footprints: Bruised Purple",
                        value: "Halloween: Bruised Purple Footprints",
                        image: require("@/assets/tf2/halloween/footprint_bruised_purple.png")
                    },
                    {
                        text: "Footprints: Headless Horseshoes",
                        value: "Halloween: Headless Horseshoes",
                        image: require("@/assets/tf2/halloween/footprint_headless_horseshoes.png")
                    },
                    {
                        text: "Paint: Die Job",
                        value: "Halloween: Die Job",
                        image: require("@/assets/tf2/halloween/paint_die_job.png")
                    },
                    {
                        text: "Paint: Spectral Spectrum",
                        value: "Halloween: Spectral Spectrum",
                        image: require("@/assets/tf2/halloween/paint_spectral_spectrum.png")
                    },
                    {
                        text: "Paint: Putrescent Pigmentation",
                        value: "Halloween: Putrescent Pigmentation",
                        image: require("@/assets/tf2/halloween/paint_putrescent_pigmentation.png")
                    },
                        {
                        text: "Paint: Sinister Staining",
                        value: "Halloween: Sinister Staining",
                        image: require("@/assets/tf2/halloween/paint_sinister_staining.png")
                    },
                    {
                        text: "Paint: Chromatic Corruption",
                        value: "Halloween: Chromatic Corruption",
                        image: require("@/assets/tf2/halloween/paint_chromatic_corruption.png")
                    },
                    {
                        text: "Effect: Exorcism",
                        value: "Halloween: Exorcism",
                        image: require("@/assets/tf2/halloween/effect_exorcism.png")
                    },
                    {
                        text: "Effect: Halloween Fire",
                        value: "Halloween: Halloween Fire",
                        image: require("@/assets/tf2/halloween/effect_halloween_fire.png")
                    },
                    {
                        text: "Effect: Pumpkin Bombs",
                        value: "Halloween: Pumpkin Bombs",
                        image: require("@/assets/tf2/halloween/effect_pumpkin_bombs.png")
                    },
                    {
                        value: "Robots Destroyed",
                        text: "Robots Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_mvm_robot_kills.148e9992636fb410c0824f560f44811f7f7ee9e7.png"
                    },
                    {
                        value: "Strange Cosmetic Part: Kills",
                        text: "Strange Cosmetic Part: Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_cosmetic_part_kills.16438424758c2ef452a78d9b886940884d1603c3.png"
                    },
                    {
                        value: "Airborne Enemies Killed",
                        text: "Airborne Enemies Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_airborne_killed.e031d44846553dbe90b541ee15cb0c1696066c2b.png"
                    },
                    {
                        value: "Damage Dealt",
                        text: "Damage Dealt",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_damage_dealt.54d3ff8e5e70497ec6348c6fd332c1777429aabc.png"
                    },
                    {
                        value: "Domination Kills",
                        text: "Domination Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killstartdomination.3e24971eebd1bb5c4bf2e7c786ca89c1def99824.png"
                    },
                    {
                        value: "Snipers Killed",
                        text: "Snipers Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_snipers_killed.06fc155fc5b6aba320c46a39896a7054b0394b91.png"
                    },
                    {
                        value: "Buildings Destroyed",
                        text: "Buildings Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_buildings_destroyed.31388803c446949aeffd18d890036bbccd63b26e.png"
                    },
                    {
                        value: "Projectiles Reflected",
                        text: "Projectiles Reflected",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_projectiles_deflected.3a23715038e817769bbdfa5cde0f623335a3928e.png"
                    },
                    {
                        value: "Headshot Kills",
                        text: "Headshot Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_headshots.09385a704fbdf75b7cdf36915d2efad9841bfb0c.png"
                    },
                    {
                        value: "Medics Killed",
                        text: "Medics Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_medics_killed.f7cdfc140a52c09e4cd8879732f32dd5e12c068f.png"
                    },
                    {
                        value: "Strange Cosmetic Part: Fires Survived",
                        text: "Strange Cosmetic Part: Fires Survived",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_fires_survived.35b8784d2b1f42cd9017d826f451b8a3682c2105.png"
                    },
                    {
                        value: "Teammates Extinguished",
                        text: "Teammates Extinguished",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_burningallyextinguished.d183a1aad6ed5fd5ca3439ccccd117cd062a549b.png"
                    },
                    {
                        value: "Strange Cosmetic Part: Freezecam Taunt Appearances",
                        text: "Strange Cosmetic Part: Freezecam Taunt Appearances",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killcam_taunts.fa3fc657bd2b52c765666b303e427a798f358a64.png"
                    },
                    {
                        value: "Spies Killed",
                        text: "Spies Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_spies_killed.11b5f2b91ea0719737a62cf845d36e02e28cf92d.png"
                    },
                    {
                        value: "Allied Healing Done",
                        text: "Allied Healing Done",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_healing_done.dc698c2255506fe8a1728660fb0d948abbeb0dff.png"
                    },
                    {
                        value: "Sappers Destroyed",
                        text: "Sappers Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_sappers_killed.3912de671dc3702f9bd038da059fbdc7238a7cc9.png"
                    },
                    {
                        value: "Player Hits",
                        text: "Player Hits",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_players_hit.d1a70fc4fd0d5408cfd356d9f5e961208431c36f.png"
                    },
                    {
                        value: "Gib Kills",
                        text: "Gib Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_enemies_gibbed.b39034d74815d845e3fff8b8cbb63a59017f632b.png"
                    },
                    {
                        value: "Scouts Killed",
                        text: "Scouts Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_scouts_killed.7d51fdd93587cdfd68e7bb8704219a7dd0621c89.png"
                    },
                    {
                        value: "Kills with a Taunt Attack",
                        text: "Kills with a Taunt Attack",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_taunt_kills.58a1de56b66d1b59b675d1721a545daf66e6fd53.png"
                    },
                    {
                        value: "Long-Distance Kills",
                        text: "Long-Distance Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_long_distance_kills.d444fdda39d96ef74484d42442eafc8ab423ab86.png"
                    },
                    {
                        value: "Giant Robots Destroyed",
                        text: "Giant Robots Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_giant_kills.9080741543e43b70656f12ca7a25c13fa78e5a2f.png"
                    },
                    {
                        value: "Critical Kills",
                        text: "Critical Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killcritical.23568bbd2b360be837050fa7a58c91c993668107.png"
                    },
                    {
                        value: "Demomen Killed",
                        text: "Demomen Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_demos_killed.1d7f2ae1775c54fa443450be3f80c3b95116bf95.png"
                    },
                    {
                        value: "Point-Blank Kills",
                        text: "Point-Blank Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_point_blank.b104c00e46bdc4d17c42c22b44e4369a1815a292.png"
                    },
                    {
                        value: "Strange Cosmetic Part: Assists",
                        text: "Strange Cosmetic Part: Assists",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_assists.1dbc6dea788436c1ead29319690ac082344156b3.png"
                    },
                    {
                        value: "Medics Killed That Have Full ÜberCharge",
                        text: "Medics Killed That Have Full ÜberCharge",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_ubers_dropped.f1ba8186261c6af5de63c4132462a71d1b171fc0.png"
                    },
                    {
                        value: "Cloaked Spies Killed",
                        text: "Cloaked Spies Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_invisibles_killed.3379ec0f5ffa26b6035da835f819a225b3aef176.png"
                    },
                    {
                        value: "Soldiers Killed",
                        text: "Soldiers Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_soldiers_killed.486d03d4cb67d1acd31468591d29476eff4f0814.png"
                    },
                    {
                        value: "Kills While Explosive Jumping",
                        text: "Kills While Explosive Jumping",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killwhileexplosivejumping.22d4cbe69634454dd7c065994316f312ac8c5baa.png"
                    },
                    {
                        value: "Low-Health Kills",
                        text: "Low-Health Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_10_percent_kills.93e3d9da305017099548ec33a63ee8a456f06c3b.png"
                    },
                    {
                        value: "Burning Enemy Kills",
                        text: "Burning Enemy Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_burning_enemies_killed.686017de7f8563a784910f68f05fde8c07ebe91b.png"
                    },
                    {
                        value: "Unusual-Wearing Player Kills",
                        text: "Unusual-Wearing Player Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_unusuals_killed.178c3fe5b2fc135dd8e03e2a0247bbb999a67d6b.png"
                    },
                    {
                        value: "Kills While Übercharged",
                        text: "Kills While Übercharged",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_kill_while_uber.3575bebe04fa7226e748397fa76faa0d2704cbc9.png"
                    },
                    {
                        value: "[NC-SF] Mann Manor (Community)",
                        text: "[NC-SF] Mann Manor (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_manor.ced31a890ed338cdd18a6e3b91dcc678c731e551.png"
                    },
                    {
                        value: "Engineers Killed",
                        text: "Engineers Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_engineers_killed.0f6b1cf2e269a48df47f32349120563957046287.png"
                    },
                    {
                        value: "Not Crit nor MiniCrit Kills",
                        text: "Not Crit nor MiniCrit Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_no_crit.b57e95f04d63eede2f752480cf62cc360694693a.png"
                    },
                    {
                        value: "Full Health Kills",
                        text: "Full Health Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_full_health_kills.88bcf81227bdc849b7cd1935a9fadf418a97653e.png"
                    },
                    {
                        value: "Killstreaks Ended",
                        text: "Killstreaks Ended",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killstreaks_ended.65a6255dba7c19ae6a195cef8864e15c4a7003de.png"
                    },
                    {
                        value: "Defender Kills",
                        text: "Defender Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_defender_kill.b448234de1f01a85c49f8ece7b64ef48cf0486f4.png"
                    },
                    {
                        value: "Posthumous Kills",
                        text: "Posthumous Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_playerkillposthumous.44985b516302f0c274f8ad2df71227a5fbae82c2.png"
                    },
                    {
                        value: "Revenge Kills",
                        text: "Revenge Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killrevenge.2eee849487227daea338f12b1ecb158a3e5ba44d.png"
                    },
                    {
                        value: "Robot Scouts Destroyed",
                        text: "Robot Scouts Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_robot_scouts_killed.31dccbbd8ba75f13057d788e16d9dcf5a3e91200.png"
                    },
                    {
                        value: "[NC-SF] Yukon (Community)",
                        text: "[NC-SF] Yukon (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_yukon.e1fe307f1b682fdf16073f46fe49623c344465c5.png"
                    },
                    {
                        value: "Heavies Killed",
                        text: "Heavies Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_heavies_killed.4df3c02b07e4159218dad8a55257bdc526956663.png"
                    },
                    {
                        value: "Tanks Destroyed",
                        text: "Tanks Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_tank_destroyed.d0a4c0184bbf0a41ecfd1a5203f45e700a80e868.png"
                    },
                    {
                        value: "Halloween Kills",
                        text: "Halloween Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_halloween_kills.ec1fdb53db8c53afa1dc499315d0007e8517c83f.png"
                    },
                    {
                        value: "Pyros Killed",
                        text: "Pyros Killed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_pyros_killed.4e3f15f1716f53a8fa6ce4689834e8c62a87db38.png"
                    },
                    {
                        value: "Kills During Victory Time",
                        text: "Kills During Victory Time",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_humiliation_kills.347d43b6919398733256fbcbfb20faf7f9c403b9.png"
                    },
                    {
                        value: "[NC-SF] Borneo (Community)",
                        text: "[NC-SF] Borneo (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_borneo.034b4173d020c71c75588e595488e2d6d8dedd76.png"
                    },
                    {
                        value: "[NC-SF] Kong King (Community)",
                        text: "[NC-SF] Kong King (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_kongking.891f9225c1f0bee9523df87f58d288b3e18df185.png"
                    },
                    {
                        value: "[NC-SF] Hoodoo (Community)",
                        text: "[NC-SF] Hoodoo (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_hoodoo.49caaf8b072dcf20d4f2047c8364eedce35256ac.png"
                    },
                    {
                        value: "[NC-SF] Suijin (Community)",
                        text: "[NC-SF] Suijin (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_suijin.4fca5d8262626ce6cf2e64a440512ff6d8ff3307.png"
                    },
                    {
                        value: "Robot Spies Destroyed",
                        text: "Robot Spies Destroyed",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_robospys_killed.6af482ec39203f0a1c03fb495938f7273cdbaca1.png"
                    },
                    {
                        value: "[NC-SF] Fastlane (Community)",
                        text: "[NC-SF] Fastlane (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_fastlane.936665341e0ae8848a411674643bc05cc7e3dae1.png"
                    },
                    {
                        value: "Taunting Player Kills",
                        text: "Taunting Player Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_conga_killer.adfaa4430c6816fae5318d946e6db1aadce0fdc0.png"
                    },
                    {
                        value: "Full Moon Kills",
                        text: "Full Moon Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_killduringfullmoon.e763e7993a4461277f377fc1dbc6d3399f6538e2.png"
                    },
                    {
                        value: "Underwater Kills",
                        text: "Underwater Kills",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_underwater_kill.d8c6f9afc979bf25d79d1d5feebe024d52215e03.png"
                    },
                    {
                        value: "[NC-SF] Turbine (Community)",
                        text: "[NC-SF] Turbine (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_turbine.2baeb4ae50ec6d38c5d6e27f246dafec8278e3b4.png"
                    },
                    {
                        value: "[NC-SF] Lakeside (Community)",
                        text: "[NC-SF] Lakeside (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_lakeside.b8f466c47d912fcdd9b160bf8f439eebef2fde0f.png"
                    },
                    {
                        value: "Robots Destroyed During Halloween",
                        text: "Robots Destroyed During Halloween",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_part_mvm_robot_kills_halloween.8f59fe767ed98d02be088df1c5bc8cec5894f5b4.png"
                    },
                    {
                        value: "[NC-SF] Frontier (Community)",
                        text: "[NC-SF] Frontier (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_frontier.59723a4687d442845d134bd9aadc966ebd53c247.png"
                    },
                    {
                        value: "[NC-SF] Snakewater (Community)",
                        text: "[NC-SF] Snakewater (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_snakewater.c0cf4d94ae8a6dc857746494891997003637c99c.png"
                    },
                    {
                        value: "[NC-SF] Steel (Community)",
                        text: "[NC-SF] Steel (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_steel.9f6611419523bb74c4715c02f039afbfb5b89fd8.png"
                    },
                    {
                        value: "[NC-SF] Harvest (Community)",
                        text: "[NC-SF] Harvest (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_harvest.c30ee8c453cc0ea6989f14a8f2dadd97be0eccdd.png"
                    },
                    {
                        value: "[NC-SF] Coldfront (Community)",
                        text: "[NC-SF] Coldfront (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_coldfront.f57858bd3be14032d7f04224f49306d9b4de771e.png"
                    },
                    {
                        value: "[NC-SF] 2Fort Invasion (Community)",
                        text: "[NC-SF] 2Fort Invasion (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_2fort_inv.a378b597b984db4d1736f9d121cf32ca571d38aa.png"
                    },
                    {
                        value: "[NC-SF] Egypt (Community)",
                        text: "[NC-SF] Egypt (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_egypt.73f44f242ea2524db2d904e3b06d5f82ea02e165.png"
                    },
                    {
                        value: "[NC-SF] Moonshine Event (Community)",
                        text: "[NC-SF] Moonshine Event (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_moonshine_event.f305a0465847fc2bba86deaebc1f675e8323881e.png"
                    },
                    {
                        value: "[NC-SF] Snowplow (Community)",
                        text: "[NC-SF] Snowplow (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_snowplow.c036807d8e0cb024a61053cc44f74724a257dd32.png"
                    },
                    {
                        value: "[NC-SF] Process (Community)",
                        text: "[NC-SF] Process (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_process.4032b55598acc457bf1d3dafc0d1e1ae6a14a6bf.png"
                    },
                    {
                        value: "[NC-SF] Watchtower (Community)",
                        text: "[NC-SF] Watchtower (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_watchtower.ac30dc978fafb293ddf79cf734aaf949b458f381.png"
                    },
                    {
                        value: "[NC-SF] Watergate (Community)",
                        text: "[NC-SF] Watergate (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_watergate.06bde1497d3ca9d3ae437cf6e1e14a7d9ca918da.png"
                    },
                    {
                        value: "[NC-SF] Gullywash (Community)",
                        text: "[NC-SF] Gullywash (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_gullywash.469f7c492f1e618dd651e89267a062655c58addf.png"
                    },
                    {
                        value: "[NC-SF] Sunshine (Community)",
                        text: "[NC-SF] Sunshine (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_sunshine.c50ddb400226bd24afeb558f1f2533873d24559d.png"
                    },
                    {
                        value: "[NC-SF] Snowycoast (Community)",
                        text: "[NC-SF] Snowycoast (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_snowycoast.18f1e9ecc727e599a41f079a22cdb90775c3dcff.png"
                    },
                    {
                        value: "[NC-SF] Byre (Community)",
                        text: "[NC-SF] Byre (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_byre.241f0cc7a201fa64c603b39b1f874121fc37c96c.png"
                    },
                    {
                        value: "[NC-SF] Offblast (Community)",
                        text: "[NC-SF] Offblast (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_offblast.3e5fb0ed728f6df3ac0f1b9d0276b919a1f29e68.png"
                    },
                    {
                        value: "[NC-SF] Standin (Community)",
                        text: "[NC-SF] Standin (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_standin.90c0c8b8438dc087b773c3e223ff59a75c4819c2.png"
                    },
                    {
                        value: "[NC-SF] Freight (Community)",
                        text: "[NC-SF] Freight (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_freight.fd601ea6e7ccec83d9a584f2c309fe0ebc84608f.png"
                    },
                    {
                        value: "[NC-SF] Vanguard (Community)",
                        text: "[NC-SF] Vanguard (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_vanguard.6e43ca60778ef348e091728d9029b664cbeaf4fc.png"
                    },
                    {
                        value: "[NC-SF] Hellstone (Community)",
                        text: "[NC-SF] Hellstone (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_millstone_event.c2e07c3390a7b61fd9e8f32c8e5e0a201a46e93a.png"
                    },
                    {
                        value: "[NC-SF] Pit of Death (Community)",
                        text: "[NC-SF] Pit of Death (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_pit_of_death.989b35017aeac439a9db4d6bb2aa31f0aa727b93.png"
                    },
                    {
                        value: "[NC-SF] Competitive",
                        text: "[NC-SF] Competitive",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_competitive.cafe3b7f942219d4192e56568fba43cc6d3b9210.png"
                    },
                    {
                        value: "[NC-SF] Highpass (Community)",
                        text: "[NC-SF] Highpass (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_highpass.7913cf694573988c277ae7ab144840944a0ccaa3.png"
                    },
                    {
                        value: "[NC-SF] Junction (Community)",
                        text: "[NC-SF] Junction (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_junction.b45b55482dca242461b24d436d25c70bd0f034c9.png"
                    },
                    {
                        value: "[NC-SF] Landfall (Community)",
                        text: "[NC-SF] Landfall (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_landfall.c92f4a69aa95dd51648076f20d07a7bcdfa73716.png"
                    },
                    {
                        value: "[NC-SF] Maple Ridge Event (Community)",
                        text: "[NC-SF] Maple Ridge Event (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_maple_ridge_event.350c3b6bcb6a675f677aad2795e57986ffeb9f3b.png"
                    },
                    {
                        value: "[NC-SF] Mountain Lab (Community)",
                        text: "[NC-SF] Mountain Lab (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_mountainlab.9d0b0ce8a3596b1ad5d658d0b8c1a13e36397171.png"
                    },
                    {
                        value: "[NC-SF] Nightfall (Community)",
                        text: "[NC-SF] Nightfall (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_nightfall.965fa39efd160f727edc2de6618c4334704d02a6.png"
                    },
                    {
                        value: "[NC-SF] Gorge Event (Community)",
                        text: "[NC-SF] Gorge Event (Community)",
                        image: "https://steamcdn-a.akamaihd.net/apps/440/icons/strange_filter_gorge_event.f5df4111dc675a62d81f72badd4079bb003a6728.png"
                    }
                ]
            }
        };
    },
    components: {
        FilterBox,
        StickerFilterBox
    },
    mounted() {
        EventBus.$on("filtersCleared", this.clearFilters);
        this.clearFilters();
    },
    methods: {
        clearFilters() {
            this.collection.selected = { value: null, text: null };
            this.playerclass.selected = { value: null, text: null };
            this.grade.selected = { value: null, text: null };
            this.exterior.selected = { value: null, text: null };
            this.unusual.selected = { value: null, text: null };
            try { this.parts.selected.splice(0, this.parts.selected.length); } catch (e) {}
        },
        filterChanged(model, value) {
            // Reset to null if not selected.
            if (value && Array.isArray(value)) {
                value = value
                    .filter((x) => !!x)
                    .map((x) => x.value)
                    .join(",")
                    .trim();
            } else {
                if (!value || !value.value) value = { value: null };
                value = value.value;
            }
            this.$store.dispatch("filters/updateFilter", { filter: model, value });
        }
    }
};
</script>

<style lang="scss">
@import "@/scss/brand.scss";

</style>