<template>
    <div
        v-on-clickaway="hideDropdown"
        @keyup.esc="onEscape"
        @keydown.up.prevent="typeAheadUp"
        @keydown.down.prevent="typeAheadDown"
        @keydown.enter.prevent="typeAheadSelect"
        class="v-select"
        :class="{'disabled': disabled}"
    >
        <div class="d-flex v-sticker-container">
            <div class="mr-2" v-if="prefix">{{ prefix }}</div>
            <div class="v-sticker" @click="toggle(i)" v-for="i in slotCount" :key="i">
                <img v-if="selectedValues[i] == null" src="@/assets/plus.svg" alt="" style="margin-top: -1px;">
                <img class="v-sticker-image" v-else :src="selectedValues[i].image" :alt="selectedValues[i].text">
                <img v-if="selectedValues[i] != null" src="@/assets/cancelStickerSelection.svg" class="sticker-cancel-icon" @click="clearSticker(i)">
            </div>
        </div>

        <div v-show="show" class="v-dropdown-container">
            <div v-if="searchable" v-show="searchable" class="v-bs-searchbox bottom-border">
                <input
                    :placeholder="labelSearchPlaceholder"
                    class="v-select-toggle"
                    type="text"
                    v-model="searchValue"
                    ref="searchSticker"
                    >

                <span class="v-search-icon" style="z-index:0;">
                    <font-awesome-icon icon="search" style="color: #081725" />
                </span>
            </div>

            <vue-custom-scrollbar tagname="div">
                <ul>
                    <li
                        v-show="searchable && filteredOptions.length === 0"
                        class="v-dropdown-item"
                    >{{ labelNotFound }} "{{ searchValue }}"</li>
                    <li
                        v-if="showDefaultOption"
                        class="v-dropdown-item disabled default-option"
                    >{{ labelTitle }}</li>
                    <li
                        v-for="(option, index) in filteredOptions"
                        :key="`v-select-${index}`"
                        class="v-dropdown-item"
                        :class="{'selected' : isSelectedOption(option, index), 'disabled': option[disabledProp]}"
                        @click="onSelect(option, index)"
                    ><img class="v-dropdown-item-image" v-if="option.image" :src="option.image"/>{{ getOptionLabel(option) }}</li>
                </ul>
            </vue-custom-scrollbar>
        </div>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: "FilterBox",
    mixins: [ clickaway ],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        disabledProp: {
            type: String,
            default: "disabled"
        },
        prefix: {
            type: String,
            default: null
        },
        labelTitle: {
            type: String,
            default: "Nothing selected"
        },
        labelNotFound: {
            type: String,
            default: "No results matched"
        },
        labelSearchPlaceholder: {
            type: String,
            default: "Search..."
        },
        slotCount: {
            type: Number,
            default: 4
        },
        options: {
            type: Array,
            default: () => []
        },
        searchable: {
            type: Boolean,
            default: false
        },
        rangeable: {
            type: Boolean,
            default: false
        },
        showDefaultOption: {
            type: Boolean,
            default: false
        },
        textProp: {
            type: String,
            default: "text"
        },
    },
    components: {
        vueCustomScrollbar
    },
    data() {
        return {
            show: false,
            selectedValues: [],
            searchValue: "",
            typeAheadPointer: -1,
            selectedSlot: 0,
        };
    },
    computed: {
        filteredOptions() {
            if (this.searchable && this.searchValue.length > 0) {
                return this.options.filter(item => {
                    if (typeof item === "object") {
                        return (
                            item[this.textProp]
                                .toLowerCase()
                                .indexOf(this.searchValue.toLowerCase()) !== -1
                        );
                    } else {
                        return (
                            item.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
                        );
                    }
                });
            }
            return this.options;
        },
        reversedOptions() {
            return [...this.filteredOptions].reverse();
        },
        lastOptionIndex() {
            return this.filteredOptions.length - 1;
        }
    },
    watch: {
        show(value){
            if (value == true){ // Focusing input when it's open
                this.$nextTick(() => this.$refs.searchSticker.focus())
            }
        }
    },
    methods: {
        clearSticker(slotIndex){
            this.selectedValues[slotIndex] = null;
            this.$emit("input", this.selectedValues);
        },
        onSelect(option, index) {
            if (option && !option[this.disabledProp]) {
                this.selectedValues[this.selectedSlot] = option;
                this.typeAheadPointer = index;
                this.hideDropdown();
                this.$emit("input", this.selectedValues);
                this.searchValue = "";
            } else if (option === null) {
                // they selected nothing?
                // this.selectedValues = [];
            }
        },
        onEscape() {
            this.hideDropdown();
        },
        typeAheadUp() {
            if (!this.show) {
                this.show = true;
            }
            if (this.typeAheadPointer > 0) {
                const nextPointer = this.typeAheadPointer - 1;
                const option = this.filteredOptions[nextPointer];
                const isDisabled = option ? option[this.disabledProp] || false : false;
                if (!isDisabled) {
                    this.typeAheadPointer--;
                } else {
                    this.typeAheadPointer--;
                    this.typeAheadUp();
                }
            } else {
                const nextEnabledOption = this.reversedOptions.findIndex(
                    o => o[this.disabledProp] !== true
                );
                this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
            }
        },
        typeAheadDown() {
            if (!this.show) {
                this.show = true;
            }
            if (this.typeAheadPointer < this.lastOptionIndex) {
                const nextPointer = this.typeAheadPointer + 1;
                const option = this.filteredOptions[nextPointer];
                const isDisabled = option ? option[this.disabledProp] || false : false;
                if (!isDisabled) {
                    this.typeAheadPointer++;
                } else {
                    this.typeAheadPointer++;
                    this.typeAheadDown();
                }
            } else {
                const nextEnabledOption = this.filteredOptions.findIndex(
                    o => o[this.disabledProp] !== true
                );
                this.typeAheadPointer = nextEnabledOption;
            }
        },
        typeAheadSelect() {
            if (this.filteredOptions[this.typeAheadPointer]) {
                this.onSelect(
                    this.filteredOptions[this.typeAheadPointer],
                    this.typeAheadPointer
                );
            }
        },
        hideDropdown() {
            this.show = false;
            this.searchValue = "";
        },
        getOptionLabel(option) {
            if (typeof option === "object") {
                return option[this.textProp];
            }
            return option;
        },
        isSelectedOption(option, index) {
            if (this.typeAheadPointer === -1 && this.selectedValues.length > 0) {
                for (let selectedValue of this.selectedValues) {
                    if (this.isEqualOption(option, this.selectedValue)) return true;
                }
            }

            return this.typeAheadPointer === index;
        },
        isEqualOption(a, b) {
            if (a && b && typeof a === "object" && typeof b === "object") {
                return (
                    a[this.textProp] === b[this.textProp] &&
                    a[this.valueProp] === b[this.valueProp]
                );
            }
            return a === b;
        },
        toggle(slotIndex) {
            // They have clicked on a sticker.
            if (!this.disabled) {
                this.show = !this.show;
                this.searchValue = "";
                this.selectedSlot = slotIndex;

                //this.selectedValues[slotIndex] = null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/brand.scss';
* {
    box-sizing: border-box;
}

input {
    width: 100%;
}

ul {
    font-size: 14px;
    color: #424242;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    padding: 0px;
    margin: 0px 0px 0px 0px;
    max-height: 200px;
}

.v-select {
    position: relative;
    width: 100%;
    min-width: 200px;
    height: 50px;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;

        .v-select-toggle {
            background-color: #f8f9fa;
            border-color: #f8f9fa;
            opacity: 0.65;
            cursor: not-allowed;

            &:focus {
                outline: 0 !important;
            }
        }
    }
}

.v-select-toggle {
    display: flex;
    justify-content: space-between;
    user-select: none;
    padding: 0.375rem 0.75rem;  
    // width: 100%;
    text-align: left;
    white-space: nowrap;
    // padding: 0.375rem 0.75rem;
    transition: background-color, border-color, box-shadow, 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #e2e6ea;
        border-color: #dae0e5;
    }

    min-width: 170px;
    width: 170px;
    // height: 48px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E2021;

    outline: none;
    border-radius: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid rgba(30, 32, 33, 0.1);
    background-color: #fff;

    &:focus {
        outline: none;
        border: 1px solid $primary;
        // outline: 1px solid $primary;
        box-shadow: 0 0 1pt 1pt $primary;
    }

    margin: 7px 10px;

}

.v-hr-spacer {
    width: 10px;
    height: 2px;
    background-color: $primary;
    margin: auto auto;
}

.v-dropdown-container {
    position: absolute;
    width: 130%;
    background: #fff;
    padding: 0.5rem 0;
    margin: 10px 0 0 0px;
    color: #212529;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    z-index: 1000;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E2021;

    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;

    border: 1px solid #E8E8E8;
    box-shadow: 0px 0px 10px rgba(30, 32, 33, 0.15);
    border-radius: 4px;

}

.v-dropdown-item {
    text-decoration: none;
    line-height: 32px;
    padding: 0px 10px 0px 10px;
    user-select: none;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E2021;

    &:hover:not(.default-option) {
        // background-color: #f8f9fa;
        color: $primary;
    }

    &.disabled {
        color: #9a9b9b;
    }

    &.selected {
        // background-color: #007bff;
        color: $primary;


        &:hover {
        // background-color: #007bff;
        color: $primary;
        }
    }

    &.disabled {
        cursor: not-allowed;

        &:hover {
        background-color: #fff;
        }
    }

    display: flex;
}

.v-bs-searchbox {
    .v-select-toggle {
        padding-left: 22px !important;
    }

    padding-bottom: 7px;
    border-bottom: 1px solid #E8E8E8;
}
.v-search-icon {
    position: absolute;
    z-index: 5;
    pointer-events: none;
    left: 18px;
    top: 18px;
    
    svg {  
        height: 12px;
        color: $primary;
        & {
            color: $primary;
        }
    }
}

.v-sticker-container {
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
}

.v-sticker {
    background: #FFFFFF;
    color: $color-navy;
    border: 1px solid $color-navy-darker;
    height: 28px;
    width: 28px;
    border-radius: 50%;

    img {
        color: $color-teal;
    }
    &:hover {
        background: #E8E8E8;

        svg {
            color: white;
        }
    }

    text-align: center;
    .v-sticker-image {
        margin: auto auto;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        position: relative;
        top:-1px;

    }
}
.v-dropdown-item-image {
    display: block;
    max-height: 28px;
    max-width: 28px;
    height: auto;
    width: auto;

    // padding-right:5px;
    border-radius: 50%;
    margin-right:10px;
}

.sticker-cancel-icon {
    position: relative;
    bottom: 38px;
    left: 10px;
    width: 50%;
}
.sticker-cancel-icon:hover{
    filter: invert(100%) sepia(85%) saturate(800%) hue-rotate(312deg) brightness(98%) contrast(640%);
    width: 60%;
}
</style>